import React from 'react';
import './JobRolesBlock.css';
import img1 from './img/img1.png'; 
import img2 from './img/img2.png'; 
import img3 from './img/img3.png'; 
import img4 from './img/img4.png'; 

const JobRolesBlock = () => {
  return (
    <div className="job-roles-block" id="job">
      <h1 className="title">Мы ищем людей</h1>
      <div className="roles-container">
        
        
        <div className="role_container">
          <div className="role">
            <div className="image-container">
              <img src={img1} alt="Дизайнер" className="role-image designer_job" />
            </div>
          </div>
          <div className="role-label">Дизайнер</div>
        </div>

        <div className="role_container">
          <div className="role">
            <div className="image-container">
              <img src={img2} alt="Медиа-Баер" className="role-image byer_job" />
            </div>
          </div>
          <div className="role-label">Медиа-Баер</div>
        </div>
        
        <div className="role_container">
          <div className="role">
            <div className="image-container">
              <img src={img3} alt="Фронтенд-Специалист" className="role-image front_job" />
            </div>
          </div>  
          <div className="role-label">Фронтенд-Специалист</div>
        </div>

        <div className="role_container">
        <div className="role">
          <div className="image-container">
            <img src={img4} alt="Бэкенд-Специалист" className="role-image" />
          </div>
        </div> 
        <div className="role-label">Бэкенд-Специалист</div>
        </div>
      </div>
    </div>
  );
};

export default JobRolesBlock;
