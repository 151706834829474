import React from 'react';
import '../tiker/Ticker.css';


function Ticker() {

  return (
    <div className="ticker-container">
      <div className="marquee_one ticker-text">
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
    </div>
    <div aria-hidden="true" className="marquee_two ticker-text">
    <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
      <span className="ticker-item">SEO ОПТИМИЗАЦИЯ</span>
      <span className="ticker-item">ИНФЛЮЕНСЕР-МАРКЕТИНГ</span>
      <span className="ticker-item">ВЕБ-ДИЗАЙН</span>
      <span className="ticker-item">БРЕНДИНГ</span>
    </div>
  </div>
  );
}

export default Ticker;





