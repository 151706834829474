import React from 'react';
import '../tiker2/Ticker2.css';
import tiker_logo from './img/logo.svg'; 

function Ticker2() {


  return (
    <div className="ticker2-container">
      <div className="ticker2-text marquee_one">
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
      </div>
      <div className="ticker2-text marquee_two">
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
        <img src={tiker_logo} className="ticker2-item" />
      </div>
    </div>
  );
}

export default Ticker2;





