import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Typewriter.css';

const Typewriter = ({ text, speed = 100, cursorBlinkCount = 5 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [cursorVisible, setCursorVisible] = useState(true);
  const [cursorBlinked, setCursorBlinked] = useState(0);

  useEffect(() => {
    let blinkTimer;
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text.charAt(index));
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timer);
    } else {
      blinkTimer = setInterval(() => {
        setCursorVisible((prev) => !prev);
        setCursorBlinked((prev) => prev + 1);
      }, 500);
      if (cursorBlinked >= cursorBlinkCount * 2) {
        clearInterval(blinkTimer);
        setCursorVisible(false);
      }
    }
    return () => clearInterval(blinkTimer);
  }, [index, text, speed, cursorBlinked, cursorBlinkCount]);

  const highlightStart = text.indexOf('любых проектов');
  const highlightEnd = highlightStart + 'любых проектов'.length;
  
  const greetStart = text.indexOf('Привет');
  const greetEnd = greetStart + 'Привет'.length;

  return (
    <div className="typewriter">
      <div className="typewriter-text">
        {displayedText.split('').map((char, i) => (
          <span
            key={i}
            className={
              (i >= highlightStart && i < highlightEnd ? 'highlight' : '') +
              (i >= greetStart && i < greetEnd ? ' greet' : '')
            }
          >
            {char}
          </span>
        ))}
        {cursorVisible && <span className="cursor" />}
      </div>
    </div>
  );
};

Typewriter.propTypes = {
  text: PropTypes.string.isRequired,
  speed: PropTypes.number,
  cursorBlinkCount: PropTypes.number,
};

export default Typewriter;