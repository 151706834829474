import "./App.css";
import React from "react";
import Header from "../header/Header";
import CarouselAdvantages from '../carousel-advantages/CarouselAdvantages';
import Ticker from '../tiker/Ticker';
import JobRolesBlock from '../job-roles-block/JobRolesBlock';
import Ticker2 from '../tiker2/Ticker2';
import ContactBlock from '../contact-block/ContactBlock';
import AdsTechBlock from '../ads-tech-block/AdsTechBlock';
import LogoBlock from '../logo-block/LogoBlock';


function App() {
  
  return (
<div className="main">
    <div className="test">
      <div className="black_block bl_top">
        <Header />
      </div>
      <Ticker />
      <div className="black_block bl_center">
        <AdsTechBlock />
        <div className="logo_carusel">
          <LogoBlock />
          <CarouselAdvantages />
        </div>

        <JobRolesBlock />
      </div>
      <Ticker2 />
      <div className="black_block bl_bot">
        <ContactBlock />
      </div>
      
    </div></div>

  );
}

export default App;
