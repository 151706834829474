import React from 'react';
import './LogoBlock.css';
import logo from './img/AdTechBlock_logo.svg'; 

const AdsTechBlock = () => {
  return (
    <div className="logo-block" id='lb'>
     
      <main className="main-content">
  
        <div className="description">
         <img id="logoContent" src={logo} alt="" className="logo"/>
         
        </div>
      </main>
      
    </div>
  );
};

export default AdsTechBlock;
